
.goupbox{position: fixed; z-index:99; top:50%; right:0; margin-top:-45px; }

.erweimabox strong{color:#f40d15;}
.erweimabox span{color:#f40d15; margin-bottom: 4px; display: block;}
.erweimabox img{width:80px;}

.notice{margin:12px 0; width:60px; height:60px; background: rgba(160,160,160,.8); text-align: center; display: flex; flex-direction: column; justify-content: center; font-size: 14px; color:#fff; cursor: pointer;}

.goup{visibility: hidden; opacity:0; width:60px; height:60px; background: rgba(160,160,160,.8); display: flex; cursor:pointer; transition-duration: 300ms;}
.goup::after{content:''; display: block; width: 18px; height:18px; border-top:4px solid #eee; border-right:4px solid #eee; transform: rotate(-45deg); margin:auto; margin-top:24px;}
.goup.show{visibility: visible; opacity:1;}

.mgoup{display:none;}

@media screen and (max-width: 800px) {
	.goupbox{display: none;}

	.mgoup{visibility: hidden; opacity:0; position: fixed; z-index:99; bottom:20px; right:10px; width:40px; height:40px; border-radius: 10px; background: rgba(160,160,160,.8); display: flex; cursor:pointer; transition-duration: 300ms;}
	.mgoup::after{content:''; display: block; width: 13px; height:13px; border-top:2px solid #eee; border-right:2px solid #eee; transform: rotate(-45deg); margin:auto; margin-top:18px;}
	.mgoup.show{visibility: visible; opacity:1;}
}